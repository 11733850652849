.nav-bar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  background-color: var(--nav-background);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  margin: 0;
  transition: all 0.3s ease;
}

@supports not (backdrop-filter: blur(10px)) {
  .nav-bar {
    opacity: 0.98;
  }
}

.nav-link {
  position: relative;
  text-decoration: none;
  color: var(--text-color);
  margin: 0.5rem 1rem;
  font-size: 1rem;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
}

.nav-link span {
  position: relative;
  z-index: 1;
}

.nav-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  color: var(--primary-color);
}

.nav-link:hover::before,
.nav-link.active::before {
  transform: scaleX(1);
  transform-origin: left;
}

.coming-soon {
  cursor: not-allowed;
  opacity: 0.7;
}

.tooltip {
  visibility: hidden;
  background-color: var(--primary-color);
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  font-size: 0.875rem;
  white-space: nowrap;
}

.coming-soon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.nav-link.active {
  animation: pulse 2s infinite;
}

@media (max-width: 768px) {
  .nav-bar {
    padding: 0.8rem 0;
  }

  .nav-link {
    margin: 0.25rem 0.5rem;
    font-size: 0.85rem;
  }

  .tooltip {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .nav-link {
    font-size: 0.8rem;
  }
}

@media (min-width: 1025px) {
  .nav-bar {
    padding: 1.25rem 0;
  }

  .nav-link {
    font-size: 0.8rem;
  }
}