@import "https://unpkg.com/open-props/easings.min.css";

.theme-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: var(--nav-blur-background);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    transition: all var(--theme-transition-duration) ease;
    padding: 0.5rem;
}

/* Fallback for browsers that don't support backdrop-filter */
@supports not (backdrop-filter: blur(10px)) {
    .theme-toggle {
        background: var(--background-color);
        opacity: 0.8;
    }
}
  
/* Base styles for sun and moon */
:root {
    --icon-fill: white;
    --icon-fill-hover: rgb(230, 230, 230);
}

.sun-and-moon {
    width: 32px;
    height: 32px;
}

.sun-and-moon > :is(.moon, .sun, .sun-beams) {
  transform-origin: center;
}

.sun-and-moon > :is(.moon, .sun) {
  fill: var(--icon-fill);
}

.theme-toggle:is(:hover, :focus-visible) > .sun-and-moon > :is(.moon, .sun) {
  fill: var(--icon-fill-hover);
}

.sun-and-moon > .sun-beams {
  stroke: var(--icon-fill);
  stroke-width: 2px;
}

.theme-toggle:is(:hover, :focus-visible) .sun-and-moon > .sun-beams {
  stroke: var(--icon-fill-hover);
}

[data-theme="dark"] .sun-and-moon > .sun {
  transform: scale(1.75);
}

[data-theme="dark"] .sun-and-moon > .sun-beams {
  opacity: 0;
}

[data-theme="dark"] .sun-and-moon > .moon > circle {
  transform: translateX(-7px);
}

@supports (cx: 1) {
  [data-theme="dark"] .sun-and-moon > .moon > circle {
    cx: 17;
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .sun-and-moon > .sun {
    transition: transform var(--theme-transition-duration) var(--ease-elastic-3);
  }

  .sun-and-moon > .sun-beams {
    transition: transform var(--theme-transition-duration) var(--ease-elastic-4),
                opacity var(--theme-transition-duration) var(--ease-3);
  }

  .sun-and-moon .moon > circle {
    transition: transform var(--theme-transition-duration) var(--ease-out-5);
  }

  @supports (cx: 1) {
    .sun-and-moon .moon > circle {
      transition: cx var(--theme-transition-duration) var(--ease-out-5);
    }
  }

  [data-theme="dark"] .sun-and-moon > .sun {
    transition-timing-function: var(--ease-3);
    transition-duration: var(--theme-transition-duration);
    transform: scale(1.75);
  }

  [data-theme="dark"] .sun-and-moon > .sun-beams {
    transition-duration: var(--theme-transition-duration);
    transform: rotateZ(-25deg);
  }

  [data-theme="dark"] .sun-and-moon > .moon > circle {
    transition-duration: var(--theme-transition-duration);
    transition-delay: calc(var(--theme-transition-duration) * 0.5);
  }
}